import { MessageService } from '../message/message.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private message: MessageService,
                private router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(
                tap(event => {
                }, error => {
                    switch (error.status) {
                        // case 400:
                        //     /********** Auto logout if 401 response returned from api **********/
                        //     console.log("error interceptor") ;
                        //     localStorage.clear();
                        //     // this.router.navigate(['']);
                        //     this.message.alert('error', '', 'You has been blocked');
                        //     break;  

                        case 401:
                            console.log("error interceptor") ;
                            localStorage.clear();
                            this.message.alert('error', 'Logged Out!', 'Currently you are logged on another device');
                            break;
                        // case 0:
                        //     this.message.alert('error', 'OOPS!', 'Please check internet connection!');
                        //     break;
                        default:
                            this.message.toast('error', error.error.message);
                            break;
                    }
                }));
    }

}
