import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';


// Directives


import { ModalModule } from 'ngx-bootstrap/modal';
import { ReplaceUnderscorePipe } from './pipe/remove-underscore';
import { LightboxModule } from 'ngx-lightbox';
import { NgxScrollToFirstInvalidModule } from '@ismaestro/ngx-scroll-to-first-invalid';
import { NgSelectModule } from '@ng-select/ng-select';
import { SeeMoreComponent } from './components/see-more/see-more.component';


import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NotfoundComponent } from '../notfound/notfound.component';

const directives = [
      ReplaceUnderscorePipe,
      SeeMoreComponent,
      NotfoundComponent,
   
];

const imports = [FormsModule,
    LightboxModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxScrollToFirstInvalidModule,
    NgSelectModule,
    NgxIntlTelInputModule, PopoverModule,
];

@NgModule({
    declarations: [
        // SeeMoreComponent ,
        ...directives
    ],
    imports: [
        CommonModule,
        ...imports,
        ModalModule.forRoot(),

    ],
    exports: [
        ...directives,
        ...imports,
        ModalModule,

    ],
    entryComponents: [],
    providers: []
})
export class SharedModule {
}
